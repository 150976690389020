import React, { useState } from "react";
import "./timecard.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTwitter,
  faLinkedin,
  faSquareGithub,
  faMedium,
  faViadeo,
  faYoutube
} from "@fortawesome/free-brands-svg-icons";

const ProjectCard = (props) => {
  const toggle = props.id;

  return (
    <>
      {/* <div className="card">
        <a style={{ color: "white", fontStyle: "normal" }} href={props.link}>
          {" "}
          <div>{props.heading}</div>{" "}
        </a>
        <div style={{ opacity: 0.6 }}>{props.time}</div>
      </div> */}

      <div class="collapsible-card">
        <div
          id="header"
          class="header"
          onClick={() => {
            if (props.id == props.openId) {
              props.setOpenid("");
            } else {
              props.setOpenid(props.id);
            }
          }}
        >
          <div className="card">
            <a
              style={{ color: "white", fontStyle: "normal" }}
              href={props.link}
            >
              <div>{props.heading}</div>
            </a>
            <div style={{ opacity: 0.6 }}>{props.time}</div>
          </div>
        </div>
        <div
          class="content"
          style={{
            height: props.id == props.openId ? "150px" : "0px",
          }}
        >
          <div style={{ textAlign: "left" }}>
            tech: <span style={{ opacity: "0.6" }}>{props.tech}</span>
          </div>
          <div style={{ textAlign: "left" }}>
            description :
            <span style={{ textAlign: "left", opacity: "0.6" }}>
              {props.description}
            </span>
          </div>
          <div style={{ display:'flex', justifyContent:'center', gap:'1rem'}}>
              <a
                target="_blank"
                className="socialIcon"
                href={props.github}
              >
                <FontAwesomeIcon icon={faSquareGithub} />
              </a>
            {props.medium ?  <a
                target="_blank"
                className="socialIcon"
                href={props.medium}
              >
                <FontAwesomeIcon icon={faMedium} />
              </a>: null}
              {props.video ?  <a
                target="_blank"
                className="socialIcon"
                href={props.video}
             
               
                
              >
                 <FontAwesomeIcon icon={faYoutube} />
              </a>: null}
          </div>
        </div>
      </div>
    </>
  );
};

export default ProjectCard;
