import TimeCard from "../components/timecard";
import Navbar from "../components/navbar";
import SocialTab from "../components/socialtab";
function About() {
  return (
    <div className="App">
      <div className="parent">
        <Navbar></Navbar>
        <span className="hi">👋 👨‍💻</span>
        <span className="hi">
        Glad to see you here,  
          
          Hi! I am Aditya, and I write code for a living. Fell in love with tech when I was 12 and never looked back.  <br></br> <br></br> Exploring new technologies is my muse.  <br></br> <br></br>
          
          I started my journey with a simple HTML page. Call it the butterfly effect, but I now find myself fascinated with backend development, systems engineering, and the core concepts of computer science. Why? Because we were put on this earth with sticks and stones, and somehow we made computers and the internet, and that baffles and intrigues me to no end. I want to be part of humankind’s greatest invention.  <br></br>
          <br></br>
          My current focus is on backend engineering, where I work on building reliable, high-performance systems. Throughout my journey, I’ve crafted robust backend services, optimized API performance, and designed scalable solutions with Docker and Kubernetes.  <br></br> <br></br> One of my recent oss project, Deployr, automates complex deployment processes, letting developers set up EC2-hosted applications with SSL, reverse proxies, and process managers in minutes.  <br></br> <br></br>While I’ve dabbled in frontends, backend systems are where I feel most at home, working across Linux environments and integrating cloud solutions to support application infrastructure.
          <br></br> <br></br>
          I’m also a big advocate of FOSS and actively contribute to open source. Currently, I’m mentoring in GSSoC, helping others build projects that foster innovation and counter monopolistic trends in tech. Open source, in my view, is key to a collaborative, forward-thinking future for software.
        </span>
        <SocialTab />
      </div>
    </div>
  );
}

export default About;
