import React, { useState } from "react";
import "./timecard.css";

const TimeCard = (props) => {
  const toggle = props.id;
  var heading ;
    if(!props.h1)
    {
       heading = 'duration' ;
    }
    else{
       heading = props.h1;
    }

  return (
    <>
      {/* <div className="card">
        <a style={{ color: "white", fontStyle: "normal" }} href={props.link}>
          {" "}
          <div>{props.heading}</div>{" "}
        </a>
        <div style={{ opacity: 0.6 }}>{props.time}</div>
      </div> */}
      
      <div class="collapsible-card">
        <div
          id="header"
          class="header"
          onClick={() => {
            if(props.id == props.openId){
                props.setOpenid('');
            }
            else{
            props.setOpenid(props.id);}
          }}
        >
        <div className="card">
        <a style={{ color: "white", fontStyle: "normal" }} href={props.link}>
        
          <div>{props.heading}</div>
        </a>
        <div style={{ opacity: 0.6 }}>{props.time}</div>
      </div>
        </div>
        <div
          class="content"
          style={{
            height: props.id == props.openId? "133px" : "0px",
          }}
        >
        <div style={{textAlign:'left'}}>{heading} : <span style={{opacity:'0.6'}}>{props.duration}</span></div>
        <div style={{textAlign:'left'}}>description :  <span style={{textAlign:'left' , opacity:'0.6'}}>{props.description}</span></div>
       
        </div>
      </div>
    </>
  );
};

export default TimeCard;
