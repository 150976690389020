import React from "react";
import "./socialtab.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTwitter , faLinkedin, faSquareGithub, faMedium } from "@fortawesome/free-brands-svg-icons";

const SocialTab = () => {
  return (
    <span>
    <div
      className="socials"
      style={{
        color: "white",
        display: "flex",
        justifyContent: "center",
        gap: "2rem",
        marginTop: "2rem",
      }}
    >
      <a target="_blank" className="socialIcon" href="https://x.com/adityastxt">
     
        <FontAwesomeIcon icon={faTwitter} />
      </a>
      <a
        target="_blank"
        className="socialIcon"
        href="https://www.linkedin.com/in/aditya-sapra-a70475252/"
      >
        <FontAwesomeIcon icon={faLinkedin} />
      </a>
      <a
        target="_blank"
        className="socialIcon"
        href="https://github.com/adityacodes30"
      >
        <FontAwesomeIcon icon={faSquareGithub} />
      </a>
      <a
        target="_blank"
        className="socialIcon"
        href="https://medium.com/@adityaework"
      >
        <FontAwesomeIcon icon={faMedium} />
      </a>
      <a
        target="_blank"
        className="socialIcon"
        href="mailto:adityaework@gmail.com"
      >
    <b>@</b>
      </a>
    </div>
    <a  target="_blank" href='https://drive.google.com/file/d/1WdcbUDiA1T4ptk7pCf4nYrBJ_oAiCFWn/view?usp=sharing' style={{fontSize:'1rem', marginTop:'1rem'}} className="socialIcon"><br></br>My resume :)</a>
    </span>
  );
};

export default SocialTab;
