import Projectcard from "./projectcard";
import { useState } from "react";

const ProjectSec = () => {

    const [open, setOpen] = useState("");
    return (
        <>
        <h4 style={{marginTop:0}} className="heading">projects :- </h4>
        <section className="section">
        <Projectcard
  heading="Deployr [ OSS - Contributions welcome ]"
  time="Oct 2024"
  tech="Golang, Linux, Bash, AWS, Nginx, PM2"
  description=" Imagine all the goodness of Next.js without Vercel. Deployr is an open-source tool that automates EC2 provisioning, SSL setup, Nginx configuration, and PM2 process management—cutting deployment complexity by 90% for a seamless hosting experience."
  link="#"
  github="https://github.com/adityacodes30/deployr"
  id="0"
  video="https://www.loom.com/share/8c7ca17efc78416d8bec92d46bc482ae?sid=10439ae0-bdd7-4ce3-ae1e-85ea4ef9da86"
  openId={open}
  setOpenid={setOpen}
/>

          <Projectcard
            heading="Catalog Scoring ONDC"
            time="jan24"
            tech=" Nodejs, Express, Docker , Kubernetes , System Design  , RabbitMQ "
            description=" Implements a catalog scoring mechanism, it utilises a distributed microservices event driven stateless architecture for scalability and working at population scale."
            link="#"
            github="https://github.com/adityacodes30/catalog-scoring-ondc"
            id="1"
            medium="https://medium.com/@adityaework/architecture-design-for-ondc-catalog-scoring-2b880922f34b"
            video="https://www.youtube.com/watch?v=dMvwqbL9Ni0"
            openId={open}
            setOpenid={setOpen}
          />
          <Projectcard
            heading="Deepword"
            time="feb24"
            tech=" Selenium, Flask, ReactJS , Cloud , Linux"
            description=" A Web portal and ML model to the detect deep fake content of a specific individual across the internet. It uses selenium to scrape the web. A selenium + chrome worker is deployed in headless mode on a remote vm"
            link="#"
            id="2"
            github="https://github.com/adityacodes30/deepword"
            openId={open}
            setOpenid={setOpen}
          />
        </section>
        </>
    )   
}

export default ProjectSec;