import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import About from './about/about';
import Work from './work/work';
import { Analytics } from '@vercel/analytics/react';
import { createBrowserRouter , RouterProvider} from 'react-router-dom';

const router = createBrowserRouter([{ path: '/', element: <App />} , { path: '/about', element: <About />} , { path: '/work', element: <Work />}]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
  <Analytics></Analytics>
  <RouterProvider router={router} />
  </React.StrictMode>
);


