import ProjectSec from "../components/projectsec";
import "../App.css";
import Expsec from "../components/expsec";
import Navbar from "../components/navbar";

import  SocialTab  from "../components/socialtab";
function Work() {
  
  return (
    <div className="App">
      <div className="parent">
        <Navbar></Navbar>
        <span className="hi">👋 👨‍💻</span>
        <Expsec></Expsec>
        <hr style={{width:'15%', opacity:'0.6'}}  />
        <ProjectSec></ProjectSec>
        <span style={{opacity:'0.7'}} className="hi">Adding more projects soon. Checkout my   <a
        target="_blank"
        style={{color: "white"}}

        href="https://github.com/adityacodes30"
      >
        github  
      </a> for a comprehensive list. </span>
        <SocialTab ></SocialTab>
      </div>
    </div>
  );
}

export default Work;
