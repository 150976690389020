import React from "react";
import { NavLink } from "react-router-dom";
import './navbar.css';

const Navbar = () => {
  return (
    <div>
      <nav className="nav">
        <span >
        <NavLink style={{textDecoration:'none', color:'white'}} to='/'> Aditya Sapra <span style={{ opacity: "0.5" }}>[20]</span></NavLink> 
        </span>
        <span className="links">
         <NavLink to='/' style={({isActive})=>{
          return isActive? {textDecoration:'none', color:'white' , opacity:'0.6'} : {textDecoration:'none', color:'white'}
         }} ><span>home</span></NavLink> 
         <NavLink to='/about' style={({isActive})=>{
          return isActive? {textDecoration:'none', color:'white' , opacity:'0.6'} : {textDecoration:'none', color:'white'}
         }}  ><span>about</span></NavLink> 
         <NavLink to='/work' style={({isActive})=>{
          return isActive? {textDecoration:'none', color:'white' , opacity:'0.6'} : {textDecoration:'none', color:'white'}
         }}  ><span>work</span></NavLink> 
        </span>
      </nav>
      <hr className="hr" />
    </div>
  );
};

export default Navbar;
